interface SuffixProps {
    suffix?: string
    className?: string
}

export default function Suffix({ suffix, className }: SuffixProps): JSX.Element {
    if (suffix) {
        return (
            <span
                className={`inline-flex items-center text-xs px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-200 text-gray-600 sm:text-sm ${className ? ` ${className}` : ''}`}
            >
                {suffix}
            </span>
        )
    }
    return <></>
}
